.section_our_project .row {
  align-items: center;
}

.our_project_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.our_project_category .project_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project_cards_box .project_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 265px;
  transition: 0.7s;
}

.project_cards_box .project_card:hover {
  background: #309df0;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.project_cards_box .project_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.project_cards_box .project_card:hover .solu_title h3,
.project_cards_box .project_card:hover .solu_description p {
  color: #fff;
}

.project_cards_box .project_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.project_cards_box .project_card:hover .solu_description button {
  background: #fff !important;
  color: #309df0;
}

.project_card .so_top_icon {
}

.project_card .solu_title h3 {
  color: #212121;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.project_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.project_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(
    140deg,
    #009cdc 0%,
    #009cdc 50%,
    #4a83e7 75%
  ) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_project_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}
.our_project_content p {
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.project_cards_box .project_card:hover .hover_color_bubble {
  top: 0rem;
}

.project_cards_box .project_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project_cards_box .project_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_project_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_project_category .project_cards_box {
    flex: auto;
  }
}
@media only screen and (min-width: 768px) {
  .our_project_category .project_cards_box {
    flex: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }
  .our_project_category {
    width: 50%;
    margin: 0 auto;
  }
}

/* card  */

.profile-card-4 h1,
.profile-card-4 h4,
a {
  font-family: "Open Sans";
  margin: 0px;
  font-size: 14px;
}

.profile-card-4 {
  max-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
}

.profile-card-4 img {
  transition: all 0.25s linear;
  opacity: 1;
}

.profile-card-4 .profile-content {
  position: relative;
  padding: 15px;
  background-color: #fff;
}

.profile-card-4 .profile-name {
  font-weight: bold;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -70px;
  color: #fff;
  font-size: 17px;
}

.profile-card-4 .profile-name p {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
}

.profile-card-4 .profile-description {
  color: #777;
  font-size: 12px;
  padding: 10px;
}

.profile-card-4 .profile-overview {
  padding: 15px 0px;
}

.profile-card-4 .profile-overview p {
  font-size: 12px;
  font-weight: 600;
  color: #777;
  margin-bottom: 0;
}

.profile-card-4 .profile-overview h4 {
  color: #273751;
  font-weight: bold;
}

.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  height: 20px;
  top: -10px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 0;
  transform: skewY(3deg);
}

.profile-card-4:hover img {
  transform: rotate(5deg) scale(1.1, 1.1);
  filter: brightness(110%);
  opacity: 0.5;
}
.profile-card-4:hover .profile-name {
  color: #4a83e7;
}
