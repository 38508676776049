@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
/* ===============Typography=============== */
h1 {
  font-size: 49px;
  font-weight: bold;
}
h2 {
  font-size: 40px;
  font-weight: bold;
}
h3,
h4 {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  padding: 0;
  margin: 0 0 20px;
}
h3 {
  font-size: 28px;
  font-weight: bold;
  color: #303030;
}
h3 span {
  font-size: 56px;
  font-weight: bold;
  color: #303030;
}
h5 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

p {
  color: #54546b;
  font-size: 18px;
}
/* ===============btn=============== */
.btn-long {
  font-size: 24px;
  font-weight: bold;
  padding: 10px 25px;
}
.btn-hang {
  margin-top: -15px;
}
/* ===============Navbar=============== */

.navbar {
  color: #1e2329;
}
.navbar p {
  font-size: 16px;
}
.navbar a:not(.btn):not(.dropdown-item) {
  color: #1e2329;
}
.sidebar-collapse .navbar .navbar-toggler-bar {
  background: #4a83e7;
}
/* =============== Section=============== */
.index-page .page-header {
  height: auto;
}
.page-header {
  min-height: auto;
}
.section {
  padding: 100px 10px;
}
.section-bg {
  background-color: #f9fafc;
}
/* =============== One Section=============== */
.one-logo {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 20px;
  width: 90px;
  height: 90px;
  margin-bottom: 15px;
}
/* Plan */
.plan {
  padding: 60px 10px 10px;
}
.plan-border {
  border: 1px solid #4a83e7;
  border-radius: 8px;
}
.plan p {
  font-size: 20px;
}
.contact-plan {
  background-color: #4a83e7;
  padding: 40px 80px;
  margin: 30px 0;
  border-radius: 12px;
}

/* Security */

ul.checkmark li {
  font-size: 16px;
  margin-bottom: 1em;
  list-style-type: none;
  position: relative;
}
ul.checkmark li:before {
  content: " ";
  display: block;
  background-color: #4a83e7;
  border: solid 0.8em #4a83e7;
  border-radius: 0.8em;
  height: 0;
  width: 0;
  position: absolute;
  left: -2.2em;
  top: 40%;
  margin-top: -0.5em;
}
ul.checkmark li:after {
  content: " ";
  display: block;
  width: 0.3em;
  height: 0.6em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  left: -1.6em;
  top: 40%;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* =============== Contact =============== */

.card-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px 1px 1px 1px;
  border-color: #e8e8e8;
  box-shadow: 0 15px 45px 0 rgb(51 51 51 / 8%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 15px;
  padding: 26px 50px;
}
.contact-icon {
  font-size: 20px;
  color: #4a83e7;
  padding: 15px;
  border: 5px solid #009cdc;
  margin-right: 20px;
}
.contact-info {
  display: flex;
  flex-direction: column;
}
.contact-title {
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  color: #777;
  font-size: 17px;
  font-weight: 400;
}
.contact-description {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
/* =============== Footer =============== */
.footer {
  padding: 0 20px;
}
.footer-menu-main {
  padding: 50px 0;
  border-bottom: 1px solid #d9e0e7;
}
.footer h6 {
  font-size: 18px;
  color: #0f2137;
  padding-bottom: 20px;
}
ul.footer-menu li {
  display: block;
  font-size: 14px;
  padding: 8px 0;
  color: #02073e;
}
.copy-right {
  padding: 25px 0 0;
}
ul.footer-copyright {
  text-align: end;
}
ul.footer-copyright li {
  display: inline-block !important;
  padding: 0 5px;
}

/* =============== Small Device =============== */

@media (max-width: 400px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 25px;
  }
  h3,
  h4 {
    font-size: 22px;
  }
  p {
    font-size: 15px;
  }
  img {
    margin: 20px 0 40px;
  }
  .btn-long {
    font-size: 17px;
    padding: 10px 25px;
  }
  .why-logo img {
    width: 60%;
  }
  .contact-plan {
    padding: 20px 25px;
  }
  .footer ul {
    padding-bottom: 20px !important;
  }
  .copy-right h3,
  ul.footer-copyright {
    text-align: center !important;
  }
}

ul li,
ol li {
  padding: 5px 0;
}
/* =============== Medium Device =============== */

@media screen and (max-width: 768px) {
}

/* =============== Large Device Device =============== */
@media screen and (max-width: 992px) {
}
